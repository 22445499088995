import React, { useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import { IoIosArrowUp } from 'react-icons/io';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import { createSlug } from '../../util/urlHelpers';
import css from './ReadmoreButton.module.css';

const BIO_COLLAPSED_LENGTH = 170;

function ReadmoreButtonComponent(props) {
  const {
    pageToRedirect,
    history,
    ensuredUser,
    listing,
    sholdRedirect,
    collapsedLength,
    pclassName,
    readmbtn,
  } = props;
  const desc = props.description;
  const [expand, setexpand] = useState(true);
  const slug = listing && createSlug(listing?.attributes?.title);
  const bCollapsedLen = collapsedLength || BIO_COLLAPSED_LENGTH;
  const collapsed = s => {
    return s?.length > bCollapsedLen ? s.substring(0, bCollapsedLen) + '...' : s;
  };
  return (
    <div>
      <div className={pclassName}>{expand ? collapsed(desc) : desc}</div>
      <div>
        {desc?.length >= BIO_COLLAPSED_LENGTH ? (
          <div
            // className={css.showdisbth}
            // style={{
            //   color: '#a91722',
            //   paddingTop: '20px',
            //   paddingBottom: '10px',
            //   fontWeight: '600',
            //   cursor: 'pointer',
            // }}
            className={readmbtn}
            onClick={() => {
              pageToRedirect === 'profile'
                ? history.push(
                    createResourceLocatorString(
                      'ProfilePageTab',
                      routeConfiguration(),
                      { id: ensuredUser.id.uuid, tab: 'listings' },
                      {}
                    )
                  )
                : pageToRedirect === 'listing' && sholdRedirect
                ? history.push(
                    createResourceLocatorString(
                      'ListingPage',
                      routeConfiguration(),
                      { id: listing.id.uuid, slug },
                      {}
                    )
                  )
                : '';
              setexpand(!expand);
            }}
          >
            {pageToRedirect && sholdRedirect ? (
              <div>Read more</div>
            ) : expand ? (
              <div className={css.readm}>
                {/* <IoIosArrowDown /> */}
                Read more
              </div>
            ) : (
              <div className={css.readm}>
                {/* <IoIosArrowUp /> */}
                Read less
              </div>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
}

const ReadmoreButton = compose(withRouter)(ReadmoreButtonComponent);

export default ReadmoreButton;
